<template>
  <v-data-table
    :footer-props="{
      'items-per-page-text': $vuetify.breakpoint.smAndDown
        ? ''
        : $t('items_per_page'),
    }"
    :headers="headersTattooer"
    :items="itemsTattooer"
    :options.sync="optionsTattooer"
    :server-items-length="totalReservations"
    :style="
      $vuetify.breakpoint.xsOnly
        ? 'margin-top: 60px;padding:1px'
        : 'padding:1px'
    "
  >
    <template v-slot:item.data="{ item }">
      {{ $d(new Date(item.start_date)) }} {{ item.start_time }} -
      {{ item.end_time }}
    </template>
    <template v-slot:item.concept="{ item }">
      {{
        item.appointment_id ? $t("boxes.Appointment") : $t("boxes.reserva_box")
      }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-row
            style="
              position: relative !important;
              display: flex;
              justify-content: center;
            "
          >
            <v-icon v-on="on" v-bind="attrs" large>mdi-dots-horizontal</v-icon>
          </v-row>
        </template>
        <v-list>
          <v-list-item class="cusList" @click="delReservation(item)">
            <v-list-item-icon>
              <v-icon small style="margin-top: -6px !important"
                >$delete</v-icon
              ></v-list-item-icon
            >
            <v-list-item-title>
              <span>{{ $t("delete", { name: "" }) }}</span></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      headersTattooer: [
        {
          text: this.$t("box"),
          align: "center",
          sortable: false,
          value: "boxes.name",
        },
        {
          text: this.$t("boxes.id"),
          align: "center",
          sortable: false,
          value: "boxes.order_by",
        },
        {
          text: this.$t("expenses.data"),
          align: "center",
          sortable: false,
          value: "data",
        },
        {
          text: this.$t("concept"),
          align: "center",
          sortable: false,
          value: "concept",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      itemsTattooer: [],
      optionsTattooer: {},
      totalReservations: null,
    };
  },
  mounted() {
    this.fetchBoxesReservation();
  },
  watch: {
    optionsTattooer: {
      handler() {
        this.fetchBoxesReservation();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("boxes", ["getBoxesReservations"]),
    fetchBoxesReservation() {
      this.getBoxesReservations({
        pagination: this.optionsTattooer,
        filters: { tattooer_id: this.$store.state.auth.user.tattooer.id },
      }).then((boxes) => {
        console.log(boxes);
        this.totalReservations = boxes.total;
        this.itemsTattooer = boxes.data;
        console.log(this.itemsTattooer);
        this.load = true;
      });
    },
    ...mapActions("tattooers", ["reservationBoxDelete"]),
    delReservation(value) {
      this.$confirm(
        value.appointments
          ? this.$t("are_you_sure_reserva_box")
          : this.$t("are_you_sure_reserva"),
        "",
        ""
      ).then(() => {
        this.id_reserva = value.reserva_id;
        //console.log(this.id_reserva);
        this.reservationBoxDelete({
          id_reserva: this.id_reserva,
        }).then((response) => {
          if (response) {
            this.$alert(this.$t("reserva_delete_success"), "", "");
            this.fetchBoxesReservation();
          }
        });
      });
    },
  },
};
</script>