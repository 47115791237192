var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{style:(_vm.$vuetify.breakpoint.xsOnly
      ? 'margin-top: 60px;padding:1px'
      : 'padding:1px'),attrs:{"footer-props":{
    'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
      ? ''
      : _vm.$t('items_per_page'),
  },"headers":_vm.headersTattooer,"items":_vm.itemsTattooer,"options":_vm.optionsTattooer,"server-items-length":_vm.totalReservations},on:{"update:options":function($event){_vm.optionsTattooer=$event}},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.start_date)))+" "+_vm._s(item.start_time)+" - "+_vm._s(item.end_time)+" ")]}},{key:"item.concept",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.appointment_id ? _vm.$t("boxes.Appointment") : _vm.$t("boxes.reserva_box"))+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-row',{staticStyle:{"position":"relative !important","display":"flex","justify-content":"center"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.delReservation(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-6px !important"},attrs:{"small":""}},[_vm._v("$delete")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("delete", { name: "" })))])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }